<template>
  <div class="default-container">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'CyDefaultLayout',
}
</script>

<style lang="scss" scoped>
.default-container {
  flex: 1;
  place-self: stretch stretch;
  background-color: cy-get-color("background");
}
</style>
